import React from 'react'
import dayjs from 'dayjs'
import PropTypes from 'prop-types'

import styles from './date-button.module.css'

const DateButton = ({
  date,
  isSelected,
  onClick,
  ariaLabel,
  ariaControls,
  className,
}) => {
  const d = dayjs(date)

  return (
    <button
      aria-label={ariaLabel}
      aria-controls={ariaControls}
      className={`${styles.dateButton} ${
        isSelected ? styles.selected : ''
      } ${className || ''}`}
      onClick={onClick}
    >
      <div className={styles.desktopDate}>
        <span className={styles.date}>{d.format('D')}</span>
        <span className={styles.month}>{d.format('MMM')}</span>
        <span className={styles.weekday}>{d.format('ddd')}</span>
      </div>
      <div className={styles.mobileDate}>
        <span className={styles.weekday}>{d.format('ddd')}</span>
        <div>
          <span className={styles.date}>{`${d.format('D')}/${d.format(
            'MM'
          )}`}</span>
        </div>
      </div>
    </button>
  )
}

DateButton.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  isSelected: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
}

export default DateButton
