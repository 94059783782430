import dayjs from 'dayjs'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import Calendar from 'react-calendar'
import { useIsMobile } from '../hooks/screen-size'

import { classes } from '../util/components'
import styles from './date-picker.module.css'
import Modal from './modal'

const DatePicker = ({
  value,
  allowedDates,
  onChange,
  showHistory,
  className,
}) => {
  const [isOpened, setIsOpened] = useState(false)
  const handleOnClick = () => setIsOpened(!isOpened)
  const calendarValue = (() => {
    if (Array.isArray(value) && value.length)
      return [value[0], value[value.length - 1]]
    else if (dayjs(value).isValid()) return dayjs(value).toDate()
    else new Date()
  })()
  const isMobile = useIsMobile()
  const isDateDisabled = allowedDates
    ? ({ date }) =>
        allowedDates.findIndex(a => dayjs(a).isSame(date, 'day')) === -1
    : () => false

  return (
    <div className={classes(styles.datePicker, className)}>
      <button onClick={handleOnClick}>
        <svg width={52} height={43} viewBox="0 0 52 43">
          <path
            fill="#919191"
            fillRule="evenodd"
            d="M0 0h52v43H0V0zm2 2v7h48V2H2zm0 9v30h48V11H2zm13 4h4v4h-4v-4zm9 0h4v4h-4v-4zm9 0h4v4h-4v-4zm9 0h4v4h-4v-4zm-27 9h4v4h-4v-4zm-9 0h4v4H6v-4zm18 0h4v4h-4v-4zm-9 9h4v4h-4v-4zm-9 0h4v4H6v-4zm18 0h4v4h-4v-4zm9-9h4v4h-4v-4zm9 0h4v4h-4v-4z"
          />
        </svg>
      </button>

      <Modal
        open={isMobile && isOpened}
        handleClose={() => setIsOpened(false)}
        backgroundClassName={styles.modalBackground}
      >
        <div className={styles.modalContainer}>
          <Calendar
            onChange={e => {
              setIsOpened(false)
              onChange(e)
            }}
            value={calendarValue}
            tileDisabled={isDateDisabled}
            minDetail="year"
            minDate={
              showHistory
                ? undefined
                : dayjs()
                    .startOf('day')
                    .toDate()
            }
          />
          <div className={styles.modalButtons}>
            <button
              className={classes(Array.isArray(value) && styles.selected)}
              onClick={() => {
                setIsOpened(false)
                onChange('all')
              }}
            >
              This Week
            </button>
            <button
              className={classes(
                dayjs()
                  .startOf('day')
                  .isSame(value, 'day') && styles.selected
              )}
              onClick={() => {
                setIsOpened(false)
                onChange(
                  dayjs()
                    .startOf('day')
                    .toDate()
                )
              }}
            >
              Today
            </button>
          </div>
        </div>
      </Modal>
      <div
        className={`${styles.calendarContainer} ${
          isOpened ? styles.calendarContainerOpen : ''
        }`}
      >
        <Calendar
          onChange={e => {
            setIsOpened(false)
            onChange(e)
          }}
          value={calendarValue}
          tileDisabled={isDateDisabled}
          minDetail="year"
          minDate={
            showHistory
              ? undefined
              : dayjs()
                  .startOf('day')
                  .toDate()
          }
        />
      </div>
    </div>
  )
}

DatePicker.propTypes = {
  value: PropTypes.any,
  allowedDates: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  onChange: PropTypes.func,
  showHistory: PropTypes.bool,
  className: PropTypes.string,
}

export default DatePicker
