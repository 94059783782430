import React, { useState } from 'react'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'

import styles from './date-filter.module.css'
import DateButton from './date-button'
import { range } from '../util/array'
import { classes } from '../util/components'
import ArrowButton from './arrow-button'
import DatePicker from './date-picker'

const getWeekStarting = startDate =>
  range(0, 6).map(d =>
    dayjs(startDate)
      .add(d, 'day')
      .startOf('day')
      .toDate()
  )

const DateFilter = ({
  className,
  dateButtonClassName,
  filterButtonClassName,
  onChange,
  onRangeChange,
  labelVerbage,
  ariaControls,
  value,
  showAll,
  lockWeek,
  showHistory,
  showCalendarWeeks,
  startDate,
  endDate,
  allowedDates,
}) => {
  const [dates, setDates] = useState(
    getWeekStarting(
      showCalendarWeeks ? dayjs(startDate).startOf('week') : startDate
    )
  )

  const goBack = () => {
    const startDate = dayjs(dates[0]).add(-1, 'week')
    const val = getWeekStarting(
      showCalendarWeeks ? startDate.startOf('week') : startDate
    )

    setDates(val)

    if (onRangeChange) {
      onRangeChange(val)
    }
  }

  const goForward = () => {
    const startDate = dayjs(dates[0]).add(1, 'week')

    const val = getWeekStarting(
      showCalendarWeeks ? startDate.startOf('week') : startDate
    )

    setDates(val)

    if (onRangeChange) {
      onRangeChange(val)
    }
  }

  return (
    <div className={classes(styles.dateFilter, className)}>
      {!lockWeek ? (
        <ArrowButton
          className={styles.arrowButton}
          direction="left"
          disabled={!showHistory && dayjs().isAfter(dates[0])}
          onClick={() => goBack()}
        />
      ) : null}
      {showAll ? (
        <button
          className={classes(
            styles.filterButton,
            filterButtonClassName,
            value === 'all' ? styles.selected : '',
            styles.first
          )}
          onClick={() => onChange('all')}
          aria-label={`Show ${labelVerbage} all week`}
          aria-controls={ariaControls}
        >
          All
          <br />
          Week
        </button>
      ) : null}
      <button
        className={classes(
          styles.filterButton,
          styles.todayButtonMobile,
          value !== 'all' ? styles.todayButton : '',
          filterButtonClassName,
          dayjs().isSame(value, 'day') && styles.selected
        )}
        onClick={() => {
          onChange(
            dayjs()
              .startOf('day')
              .toDate()
          )
          const val = getWeekStarting(dayjs().startOf('week'))
          setDates(val)
          if (onRangeChange) onRangeChange(val)
        }}
        aria-label={`Show ${labelVerbage} today`}
        aria-controls={ariaControls}
      >
        Today
      </button>
      {dates.map((date, i) =>
        dayjs().isSame(date, 'day') ? (
          <button
            key={i}
            className={classes(
              styles.filterButton,
              styles.todayButtonDesktop,
              value !== 'all' ? styles.todayButton : '',
              filterButtonClassName,
              dayjs(date).isSame(value, 'day') && styles.selected,
              i === 0 && !showAll && styles.first
            )}
            onClick={() =>
              onChange(
                dayjs()
                  .startOf('day')
                  .toDate()
              )
            }
            aria-label={`Show ${labelVerbage} today`}
            aria-controls={ariaControls}
          >
            Today
          </button>
        ) : (
          <DateButton
            key={i}
            date={date}
            isSelected={dayjs(date).isSame(value, 'day')}
            onClick={() => onChange(date)}
            ariaLabel={`Show ${labelVerbage} on ${dayjs(date).format(
              'dddd D MMMM YYYY'
            )}`}
            ariaControls={ariaControls}
            className={classes(
              styles.dateButton,
              dateButtonClassName,
              dayjs(date).isSame(value) && styles.dateButtonSelected,
              dayjs(date).isBefore() && styles.isPast,
              i === 0 && !showAll && styles.first
            )}
          ></DateButton>
        )
      )}
      {!lockWeek ? (
        <>
          <ArrowButton
            className={styles.arrowButton}
            direction="right"
            onClick={() => goForward()}
          />
          <DatePicker
            value={value === 'all' ? dates : value}
            onChange={date => {
              if (date === 'all') return onChange('all')
              const calculateSeqWeek = () => {
                const startDay = dayjs(startDate).day()
                const currentDay = dayjs(date).day()
                const newStartDay = dayjs(date).day(startDay)
                return currentDay < startDay
                  ? newStartDay.subtract(1, 'week')
                  : newStartDay
              }
              const val = getWeekStarting(
                showCalendarWeeks
                  ? dayjs(date).startOf('week')
                  : calculateSeqWeek()
              )
              onChange(date)
              setDates(val)
              if (onRangeChange) onRangeChange(val)
            }}
            showHistory={showHistory}
            className={styles.datePicker}
            allowedDates={allowedDates}
          />
        </>
      ) : null}
    </div>
  )
}

DateFilter.propTypes = {
  className: PropTypes.string,
  dateButtonClassName: PropTypes.string,
  filterButtonClassName: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onRangeChange: PropTypes.func,
  value: PropTypes.any,
  showAll: PropTypes.bool,
  lockWeek: PropTypes.bool,
  showHistory: PropTypes.bool,
  showCalendarWeeks: PropTypes.bool,
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  allowedDates: PropTypes.arrayOf(PropTypes.any),
}

DateFilter.defaultProps = {
  startDate: dayjs(),
}

export default DateFilter
